import Vue from 'vue'
import './plugins/vuetify'
// import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import config from './config/config'
import '../node_modules/vue-phone-number-input/dist/vue-phone-number-input.css';
import vueCountryRegionSelect from 'vue-country-region-select'

Vue.config.productionTip = false
Vue.use(vueCountryRegionSelect)

export const axiosPayments = axios.create({
    //uncomment baseURL if its production
    baseURL: process.env.VUE_APP_PROXY,
    headers: {
        'Accept': window.config.accept,
        'API-Key': window.config.ApiKey,
    }
});
Vue.prototype.$axiosPayments = axiosPayments;

export default Vue.extend({
    data: () => ({
        country: '',
        region: ''
    })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
