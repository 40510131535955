<template>
    <div>
    <div>game1</div>
    <div>game2</div>
    </div>
</template>

<script>
    export default {
        name: "IntroView"
    }
</script>

<style scoped>

</style>
