import { render, staticRenderFns } from "./PlaceOrder.vue?vue&type=template&id=5cb4a74e&scoped=true&"
import script from "./PlaceOrder.vue?vue&type=script&lang=js&"
export * from "./PlaceOrder.vue?vue&type=script&lang=js&"
import style0 from "./PlaceOrder.vue?vue&type=style&index=0&id=5cb4a74e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb4a74e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/vsts/work/1/s/frontend/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VContainer,VFlex,VLayout,VProgressLinear})
