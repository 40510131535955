<template>
    <v-container>

        <v-layout justify-center wrap>
            <v-flex xs12 justify-center align-content-center class="text-xs-center justify-center py-5">
                <h1 class="main-heading">Your payment has been successfully processed. Thank you.</h1>
            </v-flex>
            <v-flex xs12 md10 lg8 xl6>
                <v-progress-linear :indeterminate="true" color="#E6CD97"
                                   v-if="preloader"></v-progress-linear>
            </v-flex>
        </v-layout>

        <v-layout align-center justify-center row>
            <v-flex xs12 md10 lg8 xl6>
                <v-card class="pa-3 pt-5 mb-5">
                    <v-layout justify-space-around wrap class="pb-5">
                        <v-flex xs16 sm4 md4>
                            <h3 class="font-color-blue title font-weight-regular order-title">
                                Game Of Expert (trivia)
                            </h3>
                        </v-flex>
                        <v-flex xs12 mt-4 mb-5>
                            <v-layout justify-center wrap>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th class="order-head-center">Quantity</th>
                                        <th class="order-head-center">Unit price</th>
                                        <th class="order-head-center">Total price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Instances:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ triviaOrderDetails.instance_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForInstanceTrivia }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( triviaOrderDetails.instance_quantity * priceForInstanceTrivia
                                                ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Participants:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ triviaOrderDetails.player_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForPlayerTrivia }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( triviaOrderDetails.player_quantity * priceForPlayerTrivia
                                                ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </v-layout>
                        </v-flex>
                        <v-flex xs16 sm4 md4>
                            <h3 class="font-color-blue title font-weight-regular order-title">
                                Game Of Expert (real-life)
                            </h3>
                        </v-flex>
                        <v-flex xs12 mt-4 mb-5>
                            <v-layout justify-center wrap>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th class="order-head-center">Quantity</th>
                                        <th class="order-head-center">Unit price</th>
                                        <th class="order-head-center">Total price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Instances:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ realLifeOrderDetails.instance_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForInstanceRealLife }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( realLifeOrderDetails.instance_quantity * priceForInstanceRealLife
                                                ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Participants:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ realLifeOrderDetails.player_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForPlayerRealLife }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( realLifeOrderDetails.player_quantity * priceForPlayerRealLife
                                                ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </v-layout>
                        </v-flex>
                        <v-flex xs16 sm4 md4>
                            <h3 class="font-color-blue title font-weight-regular order-title">
                                Wisdom Of Crowds
                            </h3>
                        </v-flex>
                        <v-flex xs12 mt-4 mb-5>
                            <v-layout justify-center wrap>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th class="order-head-center">Quantity</th>
                                        <th class="order-head-center">Unit price</th>
                                        <th class="order-head-center">Total price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Instances:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ wisdomOfCrowdsOrderDetails.instance_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForInstanceWisdomOfCrowds }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( wisdomOfCrowdsOrderDetails.instance_quantity *
                                                priceForInstanceWisdomOfCrowds ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Participants:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ wisdomOfCrowdsOrderDetails.player_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForPlayerWisdomOfCrowds }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( wisdomOfCrowdsOrderDetails.player_quantity *
                                                priceForPlayerWisdomOfCrowds ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </v-layout>
                        </v-flex>
                        <v-flex xs16 sm4 md4>
                            <h3 class="font-color-blue title font-weight-regular order-title">
                                Judgment Survey
                            </h3>
                        </v-flex>
                        <v-flex xs12 mt-4 mb-5>
                            <v-layout justify-center wrap>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th class="order-head-center">Quantity</th>
                                        <th class="order-head-center">Unit price</th>
                                        <th class="order-head-center">Total price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Instances:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ surveysDetails.instance_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForInstanceSurveys }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( surveysDetails.instance_quantity * priceForInstanceSurveys
                                                ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="order-title-center">
                                                Participants:
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                <span class="bold pl-1">{{ surveysDetails.player_quantity }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{ priceForPlayerSurveys }} {{ currency }}
                                            </p>
                                        </td>
                                        <td>
                                            <p class="order-text-center">
                                                {{( surveysDetails.player_quantity * priceForPlayerSurveys
                                                ).toFixed(2)}} {{ currency }}
                                            </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout justify-center wrap class="pb-3">
                        <v-flex xs6 sm4 md3>
                            <h3 class="font-color-blue title font-weight-light">Purchase code</h3>
                        </v-flex>
                        <v-flex xs6 sm4 md5>
                            <div class="text--regular text-xs-center purchaseCode">{{purchaseCode}}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout justify-center wrap class="pb-3">
                        <v-flex xs6 sm4 md3>
                            <h3 class="font-color-blue title font-weight-light">Payment price</h3>
                        </v-flex>
                        <v-flex xs6 sm4 md5>
                            <div class="text--regular text-xs-center bold">{{paymentDetails.payment_price}}
                                {{currency}}
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card>

                <v-layout justify-center wrap class="pb-3">
                    <h2 class="main-heading">You can go back now to the admin panel and redeem your code</h2>
                    <!--<v-btn-->
                            <!--color="#E6CD97"-->
                            <!--@click="redirectToAdminPanel"-->
                    <!--&gt;-->
                        <!--Go back to Admin Panel-->
                    <!--</v-btn>-->
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "PaymentSuccess",
        components: {},
        data() {
            return {
                triviaOrderDetails: {
                    player_quantity: '',
                    player_price: '',
                    instance_quantity: '',
                    instance_price: ''
                },
                realLifeOrderDetails: {
                    player_quantity: '',
                    player_price: '',
                    instance_quantity: '',
                    instance_price: ''
                },
                wisdomOfCrowdsOrderDetails: {
                    player_quantity: '',
                    player_price: '',
                    instance_quantity: '',
                    instance_price: ''
                },
                surveysDetails: {
                    player_quantity: '',
                    player_price: '',
                    instance_quantity: '',
                    instance_price: ''
                },
                currency: '',
                purchaseCode: '',
                paymentDetails: {
                    payment_price: 0,
                    paypal_transaction_id: ''
                },
                preloader: true

            }
        },
        created() {
            this.getOrderDetailsAfterSuccessfullyPayment()
            this.$store.dispatch('getGamesPricesList')
        },
        mounted() {
        },
        computed: {
            customerId() {
                return this.$store.state.customerId
            },
            priceForInstanceTrivia() {
                let state = this.$store.state.gamesPricesList.perInstanceGoETrivia
                if (state) {
                    return state
                }
                else return 0
            },
            priceForInstanceRealLife() {
                let state = this.$store.state.gamesPricesList.perInstanceGoERealLife
                if (state) {
                    return state
                }
                else return 0
            },
            priceForInstanceWisdomOfCrowds() {
                let state = this.$store.state.gamesPricesList.perInstanceWisdomOfCrowds
                if (state) {
                    return state
                }
                else return 0
            },
            priceForInstanceSurveys() {
                let state = this.$store.state.gamesPricesList.perInstanceSurveys
                if (state) {
                    return state
                }
                else return 0
            },
            priceForPlayerTrivia() {
                let state = this.$store.state.gamesPricesList.perPlayerGoETrivia
                if (state) {
                    return state
                }
                else return 0
            },
            priceForPlayerRealLife() {
                let state = this.$store.state.gamesPricesList.perPlayerGoERealLife
                if (state) {
                    return state
                }
                else return 0
            },
            priceForPlayerWisdomOfCrowds() {
                let state = this.$store.state.gamesPricesList.perPlayerWisdomOfCrowds
                if (state) {
                    return state
                }
                else return 0
            },
            priceForPlayerSurveys() {
                let state = this.$store.state.gamesPricesList.perPlayerSurveys
                if (state) {
                    return state
                }
                else return 0
            }
        },
        methods: {

            getOrderDetailsAfterSuccessfullyPayment() {
                this.preloader = true
                this.$axiosPayments
                    .get('/api/orders/' + this.customerId + '/success/details')
                    .then((response) => {
                        this.triviaOrderDetails = response.data.success.data.details[0]
                        this.realLifeOrderDetails = response.data.success.data.details[1]
                        this.wisdomOfCrowdsOrderDetails = response.data.success.data.details[2]
                        this.surveysDetails = response.data.success.data.details[3]
                        this.currency = response.data.success.data.currency
                        this.purchaseCode = response.data.success.data.purchaseCode
                        this.paymentDetails = response.data.success.data.paymentDetails
                        this.preloader = false
                    })
                    .then(() => {
                        // this.$store.state.customerId = localStorage.clear()
                    })
            },
            // redirectToAdminPanel(){
            //     if(window.location.href === 'http://localhost:8080/#/payments/success'){
            //         window.open('http://localhost/MiddleAdmin/public/#/login')
            //     } else if(window.location.href === 'http://168.63.13.12/payments/#/payments/success') {
            //         window.open('http://168.63.13.12/admin')
            //     }else {
            //         window.open('https://admin.games.judgmentexplorer.com/')
            //     }
            //
            // }
        }
    }
</script>

<style scoped>
    .main-heading {
        padding: 0rem 2rem 0.5rem 2rem;
        border-bottom: 1px solid #cfb682;
        width: fit-content;
        margin: 0 auto;
        font-weight: normal;
    }

    body {
        padding: 5em;
        text-align: center;
    }

    h1 {
        margin-bottom: 1em;
    }

    .text--regular {
        font-size: 1.2rem;
        font-weight: lighter;
    }

    .bold {
        font-weight: bolder;
    }

    .purchaseCode {
        width: 70%;
        margin: 0 auto;
        border: 2px solid #cfb682;
        border-radius: 4px;
        font-weight: bolder;
    }

    .order-title {
        text-align: center;
    }

    .order-text-center, .order-title-center {
        text-align: center;
        margin-bottom: 0px;
    }

    .order-title-center {
        font-size: 1.5rem;
        font-weight: lighter;
    }

    .order-head-center {
        text-align: center;
        padding: 0 10px 0 10px;
        color: gray;
        font-weight: lighter;
    }

</style>
