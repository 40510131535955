<template>

    <v-container>
        <v-flex md10 offset-md1>
            <h1 class="text-xs-center main-heading">Which games would you like to buy?</h1>

            <!--<v-progress-linear :indeterminate="true" v-if="preloader"></v-progress-linear>-->

            <v-card class="px-4 pt-4 pb-3 ma-2 mt-5">
                <h1 class="gold-font header title font-weight-regular text-xs-center">Please select number of instances and
                    participants:</h1>

                <SingleOrderSelection
                    :gameName="trivia"
                    :gameId="triviaId"
                    :numberOfPlayersData="triviaPlayerQuantity"
                    :numberOfInstancesData="triviaInstanceQuantity"
                >

                </SingleOrderSelection>
                <SingleOrderSelection
                    :gameName="realLife"
                    :gameId="realLifeId"
                    :numberOfPlayersData="realLifePlayerQuantity"
                    :numberOfInstancesData="realLifeInstanceQuantity"
                >

                </SingleOrderSelection>

                <h5 class="gold-font title font-weight-regular text-xs-right">Total price: {{ this.orderTotalPrice}} $</h5>

            </v-card>

            <v-flex class="text-xs-right">
                <v-btn color="#E6CD97" @click="sendModifyedOrder">Place Order</v-btn>
            </v-flex>
        </v-flex>
    </v-container>
</template>

<script>
    import SingleOrderSelection from "../components/Modules/SingleOrderSelection";
    export default {
        name: "ModifyOrder",
        components: {SingleOrderSelection},
        data() {
            return {
                trivia: 'trivia',
                realLife: 'real-life',
                triviaId: 1,
                realLifeId: 2,

            }
        },
        created() {
            // this.$store.dispatch('getCurrentOrderData',this.$route.params.id)

        },
        computed: {

            triviaPlayerQuantity(){
                return this.$store.state.gamesOrder[0].numberOfPlayers
            },
            triviaInstanceQuantity(){
                return this.$store.state.gamesOrder[0].numberOfInstances
            },
            realLifePlayerQuantity(){
                return this.$store.state.gamesOrder[1].numberOfPlayers
            },
            realLifeInstanceQuantity(){
                return this.$store.state.gamesOrder[1].numberOfInstances
            },

            perInstanceGoERealLife(){
                return this.$store.state.gamesPricesList.perInstanceGoERealLife
            },
            perInstanceGoETrivia(){
                return this.$store.state.gamesPricesList.perInstanceGoETrivia
            },
            perPlayerGoERealLife(){
                return this.$store.state.gamesPricesList.perPlayerGoERealLife
            },
            perPlayerGoETrivia(){
                return this.$store.state.gamesPricesList.perPlayerGoETrivia
            },

            orderTotalPrice() {
                return (this.triviaPlayerQuantity * this.perPlayerGoETrivia) + (this.triviaInstanceQuantity * this.perInstanceGoETrivia)+ (this.realLifeInstanceQuantity * this.perInstanceGoERealLife) +(this.realLifePlayerQuantity* this.perPlayerGoERealLife) || 0

            }

        },
        methods: {
            sendModifyedOrder(){
                let orderData = [
                    {
                        player_quantity: this.triviaPlayerQuantity,
                        instance_quantity: this.triviaInstanceQuantity,
                        game_id: 1
                    },
                    {
                        player_quantity: this.realLifePlayerQuantity,
                        instance_quantity: this.realLifeInstanceQuantity,
                        game_id: 2
                    }
                ]
                this.$axiosPayments
                    .put('/api/orders/update/' + this.$store.state.customerId, orderData)
                    // .then((response) => {
                    //     console.log("response", response.data.success.data.customerId)
                    //     this.$store.commit('setCustomerId', response.data.success.data.customerId)
                    //
                    // })
                    .then((response) => {
                        console.log('id', this.$store.state.customerId)
                        let path = '/invoice'
                        this.$router.push(path)
                    })

            }

        }
    }
</script>

<style scoped>
    .main-heading {
        padding: 0rem 2rem 0.5rem 2rem;
        border-bottom: 1px solid #E6CD97;
        width: fit-content;
        margin: 0 auto;
        font-weight: normal;
    }
</style>
