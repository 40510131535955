<template>
    <v-app>
    <div id="app">
        <Snackbar></Snackbar>
        <navbar></navbar>
        <v-content>
            <transition name="router-animation">
            <router-view></router-view>
            </transition>
        </v-content>
    </div>
    </v-app>
</template>

<script>

    import Navbar from "./components/Navigation/Navbar";
    import Snackbar from "./components/Modules/Snackbar"
    export default {
        components: {Navbar, Snackbar}
    }
</script>


<style>
    #app {
        background-color: #F2F6F9;
        min-height:100vh;
        width: 100%
    }
    .router-animation-enter-active {
        animation: fadeInLeftBig 1s;
        animation-delay: 0.7s;
        opacity: 0;
    }
    .router-animation-leave-active {
        animation: fadeOutLeftBig 1s;


    }
    /*//animation option 1*/

    @keyframes fadeOutLeftBig {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }
    }

    .fadeOutLeftBig {
        -webkit-animation-name: fadeOutLeftBig;
        animation-name: fadeOutLeftBig;
    }

    @keyframes fadeInLeftBig {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInLeftBig {
        -webkit-animation-name: fadeInLeftBig;
        animation-name: fadeInLeftBig;
    }

/*//animation option 2*/
    /*@keyframes coming {*/
        /*from {*/
            /*-webkit-transform: translate3d(100%, 0, 0);*/
            /*transform: translate3d(100%, 0, 0);*/
            /*visibility: visible;*/
        /*}*/

        /*to {*/
            /*-webkit-transform: translate3d(0, 0, 0);*/
            /*transform: translate3d(0, 0, 0);*/
        /*}*/
    /*}*/
    /*@keyframes going {*/
        /*from {*/
            /*-webkit-transform: translate3d(0, 0, 0);*/
            /*transform: translate3d(0, 0, 0);*/
        /*}*/

        /*to {*/
            /*visibility: hidden;*/
            /*-webkit-transform: translate3d(-100%, 0, 0);*/
            /*transform: translate3d(-100%, 0, 0);*/
        /*}*/
    /*}*/

    /*//animation option 3*/
    /*@keyframes going {*/
        /*from {*/
            /*transform: translateX(0);*/
        /*}*/
        /*to {*/
            /*!*transform: translateX(-50px);*!*/
            /*transform: translateX(0px);*/
            /*opacity: 0;*/
        /*}*/
    /*}*/
    /*@keyframes coming {*/
        /*from {*/
            /*!*transform: translateX(-50px);*!*/
            /*transform: translateX(0px);*/
            /*opacity: 0;*/
        /*}*/
        /*to {*/
            /*transform: translateX(0);*/
            /*opacity: 1;*/
        /*}*/
    /*}*/

</style>
