<template>
    <v-container>
        <v-flex md10 offset-md1 lg8 offset-lg2>
            <h1 class="text-xs-center main-heading">An error has been occurred during transaction. Your credit card has not been charged. Please try again.</h1>
        </v-flex>
    </v-container>
</template>

<script>
    export default {
        name: "PaymentFailed",
        components: {},
        data() {
            return {}
        },
        created() {},
        computed: {},
        methods: {}
    }
</script>

<style scoped>
    .gold-font {
        color: #E6CD97
    }
    .main-heading {
        padding: 0rem 2rem 0.5rem 2rem;
        border-bottom: 1px solid #E6CD97;
        width: fit-content;
        margin: 0 auto;
        font-weight: normal;
    }
</style>
