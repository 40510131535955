<template>
    <div>
        <v-card class="pt-4 mt-4 mb-3">
            <v-card-text class="pt-0 pb-0">
                <v-container
                        fluid
                        grid-list-lg
                        class="pa-0 pr-2"
                >
                    <v-layout
                            row
                            wrap
                    >
                        <v-flex xs12>
                            <h3 class="font-color-blue title font-weight-regular text-xs-center">{{ gameName }}</h3>
                        </v-flex>
                        <v-flex xs12 mt-4>
                        <v-layout justify-center wrap>
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th class="order-head-center">Quantity</th>
                                    <th class="order-head-center">Unit price</th>
                                    <th class="order-head-center">Total price</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <p class="order-title-center">
                                            Instances:
                                        </p>
                                    </td>
                                    <td>
                                        <v-text-field
                                                class="order-input-center"
                                                v-model="numberOfInstances"
                                                min="0"
                                                type="number"
                                                @keypress="isInputNumber($event)"
                                                :rules="selectionRulesInstances"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <p class="order-text-center">
                                            {{ getPricePerInstance(gameId) }} $
                                        </p>
                                    </td>
                                    <td>
                                        <p class="order-text-center">
                                            {{ (numberOfInstances * getPricePerInstance(gameId)).toFixed(2) }} $
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="order-title-center">
                                            Participants:
                                        </p>
                                    </td>
                                    <td>
                                        <v-text-field
                                                class="order-input-center"
                                                v-model="numberOfPlayers"
                                                min="0"
                                                type="number"
                                                @keypress="isInputNumber($event)"
                                                :rules="selectionRulesParticipants"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <p class="order-text-center">
                                            {{ getPricePerPlayers(gameId) }} $
                                        </p>
                                    </td>
                                    <td>
                                        <p class="order-text-center">
                                            {{ (numberOfPlayers * getPricePerPlayers(gameId)).toFixed(2) }} $
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <h3 class="mb-3 subheading price-font-style text-xs-right">Price for {{gameName}}: <span class="font-color-gold">{{totalPrice}} $</span></h3>
                        </v-flex>
                        </v-layout>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: "SingleOrderSelection",
        props: ['gameName', 'numberOfPlayersData', 'numberOfInstancesData', 'gameId'],
        data() {
            return {}
        },
        computed: {
            selectionRulesInstances() {
                return this.$store.state.selectionRulesInstances
            },
            selectionRulesParticipants() {
                return this.$store.state.selectionRulesParticipants
            },

            numberOfPlayers: {
                get: function () {
                    return this.numberOfPlayersData
                },
                set: function (value) {
                    if (value === '') {value = 0}
                    return this.$store.commit('changeNumberOfPlayers', {
                        value: parseInt(value),
                        game_id: this.gameId
                    })
                }
            },
            numberOfInstances: {
                get: function () {
                    return this.numberOfInstancesData
                },
                set: function (value) {
                    if (value === '') {value = 0}
                    return this.$store.commit('changeNumberOfInstances', {
                        value: parseInt(value),
                        game_id: this.gameId
                    })
                }
            },
            priceForInstanceTrivia() {
                return this.$store.state.gamesPricesList.perInstanceGoETrivia
            },
            priceForInstanceRealLife() {
                return this.$store.state.gamesPricesList.perInstanceGoERealLife
            },
            priceForInstanceWisdomOfCrowds() {
                return this.$store.state.gamesPricesList.perInstanceWisdomOfCrowds
            },
            priceForInstanceSurveys() {
                return this.$store.state.gamesPricesList.perInstanceSurveys
            },
            priceForPlayerTrivia() {
                return this.$store.state.gamesPricesList.perPlayerGoETrivia
            },
            priceForPlayerRealLife() {
                return this.$store.state.gamesPricesList.perPlayerGoERealLife
            },
            priceForPlayerWisdomOfCrowds() {
                return this.$store.state.gamesPricesList.perPlayerWisdomOfCrowds
            },
            priceForPlayerSurveys() {
                return this.$store.state.gamesPricesList.perPlayerSurveys
            },
            totalPrice() {
                if (this.gameId == 1) {
                    return Math.round(((this.priceForInstanceTrivia * this.numberOfInstancesData) + (this.priceForPlayerTrivia * this.numberOfPlayers)) * 100) / 100 || 0
                } else if (this.gameId == 2) {
                    return Math.round(((this.priceForInstanceRealLife * this.numberOfInstancesData) + (this.priceForPlayerRealLife * this.numberOfPlayers)) * 100) / 100 || 0
                } else if (this.gameId == 3) {
                    return Math.round(((this.priceForInstanceWisdomOfCrowds * this.numberOfInstancesData) + (this.priceForPlayerWisdomOfCrowds * this.numberOfPlayers)) * 100) / 100 || 0
                } else if (this.gameId == 4) {
                    return Math.round(((this.priceForInstanceSurveys * this.numberOfInstancesData) + (this.priceForPlayerSurveys * this.numberOfPlayers)) * 100) / 100 || 0
                }
                else return 0
            }
        },
        methods: {
            getPricePerInstance(gameId) {
                if(gameId === 1) {
                    return this.priceForInstanceTrivia
                } else if(gameId === 2) {
                    return this.priceForInstanceRealLife
                } else if (gameId === 3) {
                    return this.priceForInstanceWisdomOfCrowds
                } else if (gameId === 4) {
                    return this.priceForInstanceSurveys
                }
            },
            getPricePerPlayers(gameId) {
                if(gameId === 1) {
                    return this.priceForPlayerTrivia
                } else if(gameId === 2) {
                    return this.priceForPlayerRealLife
                } else if (gameId === 3) {
                    return this.priceForPlayerWisdomOfCrowds
                } else if (gameId === 4) {
                    return this.priceForPlayerSurveys
                }
            },
            isInputNumber: function (evt) {
                var ch = String.fromCharCode(evt.which);

                if(!(/[0-9]/.test(ch))){
                    evt.preventDefault();
                }
            }
        }
    }
</script>


<style lang="scss">

    $input-height: 40px;
    $input-border-radius: 8px;

    .v-text-field {

        .v-input__append-outer,
        .v-input__prepend-outer {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .v-input__prepend-inner {
            margin-right: 8px;
        }
        .v-input__append-inner {
            margin-left: 8px;
        }

        .v-input__slot {
            height: $input-height;
            padding: 4px 8px;
            border: 1px solid #cfb682;
            border-radius: $input-border-radius;
            transition: border-color 0.2s ease-in-out;

            // Removed default input underline
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }

            input {
                padding: 0;
            }

        }

        // Highlight currently focused field
        &.v-input--is-focused {

        }

    }

    .v-slider__thumb,
    .v-slider
    .primary {
        background-color: #E6CD97 !important;
        border-color: #E6CD97 !important;
    }

    .menu__content--select {
        border-radius: 8px;
    }

    .font-color-blue {
        color: #353E4E
    }
    .font-color-gold {
        color: #cfb682;
        font-weight: normal;
    }
    .price-font-style {
        color: #0000008a
    }

    .order-text-center, .order-title-center {
        text-align: center;
        margin-bottom: 0px;
    }
    .order-title-center {
        font-size: 1.5rem;
        font-weight: lighter;
    }
    .order-input-center {
        width: 80%;
        margin: 0 auto;
    }

    .order-head-center {
        text-align: center;
        padding: 0 10px 0 10px;
        color: gray;
        font-weight: lighter;
    }
</style>
