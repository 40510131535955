import Vue from 'vue'
import Router from 'vue-router'
import IntroView from './views/IntroView'
import GoeTriviaOrderSelection from './views/GoeTriviaOrderSelection'
import GoeRealLifeOrderSelection from './views/GoeRealLifeOrderSelection'
import Cart from './views/Cart'
import InvoiceInformation from './views/InvoiceInformation'
import ModifyOrder from './views/ModifyOrder'
import PaymentMethod from './views/PaymentMethod'
import PlaceOrder from './views/PlaceOrder'
import PaymentSuccess from './views/PaymentSuccess'
import PaymentError from './views/PaymentError'
import PaymentFailed from './views/PaymentFailed'
import ProceedPaymentsLoader from './views/ProceedPaymentsLoader'

Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [
        { path: '*', component: IntroView },
        {
            path: '/',
            name: 'home',
            component: PlaceOrder,
            redirect: '/orders/store',

        },
        {
            name: 'orders',
            path: '/orders/store',
            component: PlaceOrder,
        },
        {
            name: 'order-trivia',
            path: '/orders/store/trivia',
            component: GoeTriviaOrderSelection,
        },
        {
            name: 'order-real-life',
            path: '/orders/store/real-life',
            component: GoeRealLifeOrderSelection,
        },
        {
            name: 'cart',
            path: '/cart',
            component: Cart,
        },
        {
            name: 'invoice',
            path: '/invoice',
            component: InvoiceInformation,
        },
        {
            name: 'modify-invoice',
            path: '/invoice/update/:customerId',
            component: InvoiceInformation,
            props: true
        },
        {
            name: 'modify-order',
            path: '/orders/update/:customerId',
            // component: ModifyOrder,
            component: PlaceOrder,
            props: true
        },
        {
            name: 'payments',
            path: '/payments',
            component: PaymentMethod,
        },
        {
            name: 'payments/success',
            path: '/payments/success',
            component: PaymentSuccess,
        },
        {
            name: 'payments/error',
            path: '/payments/error',
            component: PaymentError,
        },,
        {
            name: 'payments/failed',
            path: '/payments/failed',
            component: PaymentFailed,
        },
        {
            name: 'payments-loader',
            path: '/payments/processing',
            component:  ProceedPaymentsLoader,
        }
        // {
        //   path: '/about',
        //   name: 'about',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        // }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
