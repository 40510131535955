window.config = {
    "accept": "application/json",
    "ApiKey": "7b0f8e08-524b-4fd1-8776-764f86ab689c",

    // ===============================UNCOMMENT YOUR CHOICE ==========================================

    //LOCALHOST
    // "backendAPIPath": "http://localhost/InseadPayments/backend/public/"

    //DEV
    // "backendAPIPath": "http://168.63.13.12/payments/backend/public/",

    //PROD
    "backendAPIPath": "https://payments.judgmentexplorer.com/backend/public/"
}