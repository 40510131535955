<template>
    <v-container>
        <v-layout justify-center wrap>
            <v-flex xs12 justify-center align-content-center class="text-xs-center justify-center py-5">
                <h1 class="text-xs-center main-heading">Which games would you like to buy?</h1>
            </v-flex>
            <v-flex xs12 md10 lg8 xl6>
                <v-progress-linear :indeterminate="true" color="#E6CD97" v-if="preloader"></v-progress-linear>
            </v-flex>
        </v-layout>

        <v-layout align-center justify-center row>
            <v-flex xs12 md10 lg8 xl6>
                <v-card class="px-4 pt-4 pb-3 mb-4">
                    <h1 class="gold-font header title font-weight-regular text-xs-center">Please select number of
                        instances
                        and
                        participants:</h1>

                    <SingleOrderSelection
                            :gameName="trivia"
                            :gameId="triviaId"
                            :numberOfPlayersData="triviaPlayerQuantity"
                            :numberOfInstancesData="triviaInstanceQuantity"
                    >
                    </SingleOrderSelection>

                    <SingleOrderSelection
                            :gameName="realLife"
                            :gameId="realLifeId"
                            :numberOfPlayersData="realLifePlayerQuantity"
                            :numberOfInstancesData="realLifeInstanceQuantity"
                    >
                    </SingleOrderSelection>

                    <SingleOrderSelection
                            :gameName="wisdomOfCrowds"
                            :gameId="wisdomOfCrowdsId"
                            :numberOfPlayersData="wisdomOfCrowdsPlayerQuantity"
                            :numberOfInstancesData="wisdomOfCrowdsInstanceQuantity"
                    >
                    </SingleOrderSelection>

                    <SingleOrderSelection
                            :gameName="Surveys"
                            :gameId="SurveysId"
                            :numberOfPlayersData="surveysPlayerQuantity"
                            :numberOfInstancesData="surveysInstanceQuantity"
                    >
                    </SingleOrderSelection>

                    <h5 class="gold-font title font-weight-regular text-xs-right mt-4 mb-2">Total price: {{
                        this.orderTotalPrice
                        }}
                        $</h5>

                </v-card>
            </v-flex>
        </v-layout>

        <v-layout align-center justify-center>
            <v-flex xs12 md10 lg8 xl6>
                <v-flex class="text-xs-right">
                    <v-btn v-if="editMode" color="#E6CD97" @click="sendOrder" :loading="buttonLoading"
                           :disabled="this.isDisabled">
                        Place Order
                    </v-btn>
                    <v-btn v-else color="#E6CD97" @click="sendOrder" :loading="buttonLoading"
                           :disabled="this.isDisabled || this.snackbarError">
                        Place Order
                    </v-btn>
                </v-flex>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import SingleOrderSelection from "../components/Modules/SingleOrderSelection";

    export default {
        name: "PlaceOrder",
        components: {SingleOrderSelection},
        data() {
            return {
                trivia: 'Game Of Experts (Trivia)',
                realLife: 'Game Of Experts (Real-Life)',
                wisdomOfCrowds: 'Wisdom Of Crowds',
                Surveys: 'Judgment Survey',
                triviaId: 1,
                realLifeId: 2,
                wisdomOfCrowdsId: 3,
                SurveysId: 4,
                editMode: false,
                buttonLoading: false
            }
        },
        created() {
            this.$store.dispatch('getGamesPricesList')
            if (this.$route.name === 'modify-order') {
                this.editMode = true
                this.$store.dispatch('getOrderData', this.$route.params.customerId)
            }
        },
        computed: {

            triviaPlayerQuantity() {
                return this.$store.state.gamesOrder[0].player_quantity
            },
            triviaInstanceQuantity() {
                return this.$store.state.gamesOrder[0].instance_quantity
            },
            realLifePlayerQuantity() {
                return this.$store.state.gamesOrder[1].player_quantity
            },
            realLifeInstanceQuantity() {
                return this.$store.state.gamesOrder[1].instance_quantity
            },
            wisdomOfCrowdsPlayerQuantity() {
                return this.$store.state.gamesOrder[2].player_quantity
            },
            surveysPlayerQuantity() {
                return this.$store.state.gamesOrder[3].player_quantity
            },
            wisdomOfCrowdsInstanceQuantity() {
                return this.$store.state.gamesOrder[2].instance_quantity
            },
            surveysInstanceQuantity() {
                return this.$store.state.gamesOrder[3].instance_quantity
            },
            snackbarError() {
                return this.$store.state.snackbar.open
            },
            perInstanceGoERealLife() {
                return this.$store.state.gamesPricesList.perInstanceGoERealLife
            },
            perInstanceGoETrivia() {
                return this.$store.state.gamesPricesList.perInstanceGoETrivia
            },
            perInstanceWisdomOfCrowds() {
                return this.$store.state.gamesPricesList.perInstanceWisdomOfCrowds
            },
            perInstanceSurveys() {
                return this.$store.state.gamesPricesList.perInstanceSurveys
            },
            perPlayerGoERealLife() {
                return this.$store.state.gamesPricesList.perPlayerGoERealLife
            },
            perPlayerGoETrivia() {
                return this.$store.state.gamesPricesList.perPlayerGoETrivia
            },
            perPlayerWisdomOfCrowds() {
                return this.$store.state.gamesPricesList.perPlayerWisdomOfCrowds
            },
            perPlayerSurveys() {
                return this.$store.state.gamesPricesList.perPlayerSurveys
            },

            orderTotalPrice() {
                return Math.round(((this.triviaPlayerQuantity * this.perPlayerGoETrivia) + (this.triviaInstanceQuantity * this.perInstanceGoETrivia)
                    + (this.realLifeInstanceQuantity * this.perInstanceGoERealLife) + (this.realLifePlayerQuantity * this.perPlayerGoERealLife)
                    + (this.wisdomOfCrowdsInstanceQuantity * this.perInstanceWisdomOfCrowds) + (this.wisdomOfCrowdsPlayerQuantity * this.perPlayerWisdomOfCrowds)
                    + (this.surveysInstanceQuantity * this.perInstanceSurveys) + (this.surveysPlayerQuantity * this.perPlayerSurveys)) * 100) / 100
                    || 0

            },
            preloader() {
                return this.$store.state.preloader
            },
            isDisabled() {

                if (this.orderTotalPrice == 0) {
                    return true
                } else {
                    return false
                }
            },
            selectionRulesInstances() {
                return this.$store.state.selectionRulesInstances
            },
            selectionRulesParticipants() {
                return this.$store.state.selectionRulesParticipants
            },

        },
        methods: {
            sendOrder() {
                let orderData = [
                    {
                        player_quantity: this.triviaPlayerQuantity,
                        instance_quantity: this.triviaInstanceQuantity,
                        game_id: 1
                    },
                    {
                        player_quantity: this.realLifePlayerQuantity,
                        instance_quantity: this.realLifeInstanceQuantity,
                        game_id: 2
                    },
                    {
                        player_quantity: this.wisdomOfCrowdsPlayerQuantity,
                        instance_quantity: this.wisdomOfCrowdsInstanceQuantity,
                        game_id: 3
                    },
                    {
                        player_quantity: this.surveysPlayerQuantity,
                        instance_quantity: this.surveysInstanceQuantity,
                        game_id: 4
                    }
                ]
                if (this.editMode) {
                    this.buttonLoading = true
                    this.$axiosPayments
                        .put('/api/orders/update/' + this.$store.state.customerId, orderData)
                        .then((response) => {
                            this.$store.commit('setCustomerId', response.data.success.data.customerId)
                            if(this.$route.name === 'orders') {
                                let path = '/invoice'
                                this.$router.push(path)
                            } else {
                                let path = '/invoice/update/' + this.$store.state.customerId
                                this.$router.push(path)
                            }

                            this.buttonLoading = false
                        }).catch(error => {
                        this.$store.dispatch('handleErrorMessage', error.response)
                        this.$store.dispatch('makeSnackbarNotification', {
                            open: true,
                            message: this.$store.state.errorMessage,
                            type: 'error'
                        })
                        this.buttonLoading = false
                    })
                } else {
                    this.buttonLoading = true
                    this.$axiosPayments

                        .post('/api/orders/store', orderData)
                        .then((response) => {
                            this.$store.commit('setCustomerId', response.data.success.data.customerId)
                            localStorage.setItem('customerId', response.data.success.data.customerId);
                        })
                        .then((response) => {
                            let path = '/invoice'
                            this.$router.push(path)
                            this.buttonLoading = false
                        }).catch(error => {
                        this.$store.dispatch('handleErrorMessage', error.response)
                        this.$store.dispatch('makeSnackbarNotification', {
                            open: true,
                            message: this.$store.state.errorMessage,
                            type: 'error'
                        })
                        this.buttonLoading = false
                    })
                }


            },

        }
    }
</script>

<style scoped>
    .gold-font {
        /*color: #E6CD97*/
        color: #cfb682
    }

    .main-heading {
        padding: 0rem 2rem 0.5rem 2rem;
        border-bottom: 1px solid #cfb682;
        width: fit-content;
        margin: 0 auto;
        font-weight: normal;
    }
</style>
