<template>
    <v-container>
        <form @submit.prevent="submitForm">
            <v-layout justify-center wrap>
                <v-flex xs12 justify-center align-content-center class="text-xs-center justify-center py-5">
                    <h1 class="main-heading">Please fill in all information for invoice</h1>
                </v-flex>
                <v-flex xs12 md10 lg8 xl6>
                    <v-progress-linear :indeterminate="true" color="#E6CD97" v-if="preloader && $route.name === 'modify-invoice'"></v-progress-linear>
                </v-flex>
            </v-layout>
            <v-layout align-center justify-center row>
                <v-flex xs12 md10 lg8 xl6>
                    <v-card class="pa-3 mb-5">
                        <v-layout justify-center wrap>
                            <v-flex xs12>
                                <h3 class="text-xs-center pa-3 gold-font">Personal information:</h3>
                            </v-flex>
                            <v-flex xs11 md9 class="pt-2 pb-5">
                                <v-text-field
                                        label="First name"
                                        v-model="firstName"
                                        :rules="firstNameRules"
                                        :counter="25"
                                        outline
                                        required
                                >
                                </v-text-field>

                                <v-text-field
                                        label="Last name"
                                        v-model="lastName"
                                        :rules="lastNameRules"
                                        :counter="50"
                                        outline
                                        required
                                >
                                </v-text-field>

                                <v-text-field
                                        label="E-mail address"
                                        v-model="email"
                                        :rules="emailRules"
                                        :counter="30"
                                        outline
                                        required
                                >
                                </v-text-field>

                                <VuePhoneNumberInput
                                        color="red"
                                        valid-color="#E6CD97"
                                        v-model="phone"
                                        :default-country-code="phoneDetails.countryCode"
                                        :translations="phoneMessages"
                                        @update="updatePhone"
                                />

                            </v-flex>


                        </v-layout>
                    </v-card>

                    <v-card class="pa-3 mb-4">
                        <v-layout justify-center wrap>
                            <v-flex xs12>
                                <h3 class="text-xs-center pa-3 gold-font">Company information:</h3>
                            </v-flex>
                            <v-flex xs11 md9 class="pt-2 pb-5">

                                <v-text-field
                                        label="Company name"
                                        v-model="companyName"
                                        :rules="companyNameRules"
                                        :counter="70"
                                        outline
                                        required
                                >
                                </v-text-field>

                                <v-text-field
                                        label="Tax reference number"
                                        v-model="taxReferenceNumber"
                                        :rules="taxReferenceNumberRules"
                                        :counter="80"
                                        outline
                                        required
                                >
                                </v-text-field>

                                <v-text-field
                                        label="Address line 1"
                                        v-model="addressPart1"
                                        :rules="addressPart1Rules"
                                        outline
                                        required
                                >
                                </v-text-field>

                                <!--<v-text-field-->
                                        <!--label="Address line 2"-->
                                        <!--v-model="addressPart2"-->
                                        <!--:rules="addressPart2Rules"-->
                                        <!--outline-->
                                <!--&gt;-->
                                <!--</v-text-field>-->

                                <v-layout justify-space-between row>
                                    <v-flex xs6>
                                        <v-text-field
                                                label="Post code"
                                                v-model="postCode"
                                                :rules="postCodeRules"
                                                :counter="40"
                                                style="margin-right: 4%"

                                                outline
                                                required
                                        >
                                        </v-text-field>
                                    </v-flex>

                                    <v-flex xs6>
                                        <v-text-field
                                                label="City"
                                                v-model="city"
                                                :counter="40"
                                                :rules="cityRules"

                                                outline
                                                required
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>

                                <!--<v-text-field-->
                                        <!--label="Region"-->
                                        <!--v-model="region"-->
                                        <!--:counter="40"-->
                                        <!--:rules="regionRules"-->
                                        <!--outline-->
                                        <!--required-->
                                <!--&gt;-->
                                <!--</v-text-field>-->

                                <!--<v-text-field-->
                                        <!--label="Country"-->
                                        <!--v-model="country"-->
                                        <!--:counter="40"-->
                                        <!--:rules="countryRules"-->
                                        <!--outline-->
                                        <!--required-->
                                <!--&gt;-->
                                <!--</v-text-field>-->
                                <div>
                                    <country-select
                                            id="select-country-id"
                                            :class="selectCountry"
                                            class="select-country"
                                            v-model="country"
                                            :country="country"
                                            :countryName=true
                                            topCountry="US" />
                                </div>
                                <div class="ma-2">
                                    <div class="v-messages theme--light error--text">
                                        <div v-show="showCoutryError">{{ countryRules[0] }}</div>
                                    </div>
                                </div>

                                <v-textarea
                                        outline
                                        label="Notes"
                                        v-model="notes"
                                        height="140"
                                        no-resize
                                        :counter="600"
                                        :roules="notesRules"
                                ></v-textarea>
                            </v-flex>


                        </v-layout>
                    </v-card>


                </v-flex>
            </v-layout>

            <v-layout align-center justify-center>
                <v-flex xs12 md10 lg8 xl6>
                    <v-layout>
                        <v-flex xs6 class="text-xs-left">
                            <!--<v-btn @click="$router.go(-1)">BACK TO ORDER</v-btn>-->
                            <v-btn @click="modifyOrder" >BACK TO ORDER</v-btn>
                        </v-flex>
                        <v-flex xs6 class="text-xs-right">
                            <v-btn type="submit" color="#E6CD97" :disabled=validation :loading="buttonLoading">PROCEED TO PAYMENT</v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </form>
    </v-container>
</template>

<script>

    import VuePhoneNumberInput from 'vue-phone-number-input';
    // import countrySelect from 'vue-country-region-select'

    export default {
        name: "InvoiceInformation",
        components: {VuePhoneNumberInput},
        created() {
            if (this.$route.name === 'modify-invoice' && this.$store.state.customerInformation.firstName === '') {
                this.$store.dispatch('getInvoiceData', this.$route.params.customerId)
                this.phone = localStorage.getItem('nationalNumber')
                this.phoneDetails.countryCode = localStorage.getItem('countryCode')
            }
            if (this.$route.name === 'invoice') {
                localStorage.removeItem('nationalNumber')
                localStorage.removeItem('countryCode')
            }
        },
        data() {
            return {
                buttonLoading: false,
                countryChanged: false,
                showCoutryError: false,
                phoneMessages: {
                    example: 'Incorrect number. Exemple:'
                },
                phoneDetails: {
                    formattedNumber: '',
                    isValid: false,
                    countryCode: localStorage.getItem('countryCode') || 'US',
                    formatInternational: ''
                },
                firstNameRules: [
                    v => !!v || 'First name is required',
                    v => v && v.length <= 25 || 'First name must be less than 26 characters'
                ],
                lastNameRules: [
                    v => !!v || 'Last name is required',
                    v => v && v.length <= 50 || 'Last name must be less than 51 characters'
                ],
                emailRules: [
                    v => !!v || 'Email is required',
                    v => (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(v) || 'Invalid email address'
                    // v => v && v.length <= 30 || 'Last name must be less than 31 characters'
                ],
                phoneRules: [
                    v => !!v || 'Phone number is required',
                    v => v && (/^\d{4}-\d{3}-\d{4}$/).test(v) || 'xxxx-xxx-xxxx is valid format'
                ],
                taxReferenceNumberRules: [
                    v => !!v || 'Tax reference number is required',
                    // v => v && v.length <= 25 || 'Last name must be less than 101 characters'
                ],
                companyNameRules: [
                    v => !!v || 'Company name is required',
                    v => v && v.length <= 70 || 'Company name must be less than 71 characters'
                ],
                addressPart1Rules: [
                    v => !!v || 'Address is required',
                ],
                addressPart2Rules: [
                    // v => !!v || 'Address is required',
                ],
                postCodeRules: [
                    v => !!v || 'Post code is required',
                    // v => v && v.length <= 25 || 'Last name must be less than 101 characters'
                ],
                cityRules: [
                    v => !!v || 'City name is required',
                    // v => v && v.length <= 25 || 'Last name must be less than 101 characters'
                ],
                countryRules: [
                    'Country name is required',
                    // v => v && v.length <= 25 || 'Last name must be less than 101 characters'
                ],
                // regionRules: [
                //     v => !!v || 'Region name is required',
                //     // v => v && v.length <= 25 || 'Last name must be less than 101 characters'
                // ],
                notesRules: [
                    v => v && v.length <= 600 || 'Notes must be less than 601 characters'
                ]
            }
        },
        computed: {

            customerId() {
                return this.$store.state.customerId
            },
            preloader() {
                return this.$store.state.preloader
            },
            firstName: {
                get() {
                    return this.$store.state.customerInformation.firstName
                },
                set(value) {
                    this.$store.commit('UPDATE_FIRST_NAME', value)
                }
            },
            lastName: {
                get() {
                    return this.$store.state.customerInformation.lastName
                },
                set(value) {
                    this.$store.commit('UPDATE_LAST_NAME', value)
                }
            },
            email: {
                get() {
                    return this.$store.state.customerInformation.email
                },
                set(value) {
                    this.$store.commit('UPDATE_EMAIL', value)
                }
            },
            taxReferenceNumber: {
                get() {
                    return this.$store.state.customerInformation.taxReferenceNumber
                },
                set(value) {
                    this.$store.commit('UPDATE_TAXNUMBER', value)
                }
            },
            companyName: {
                get() {
                    return this.$store.state.customerInformation.companyName
                },
                set(value) {
                    this.$store.commit('UPDATE_COMPANY', value)
                }
            },
            addressPart1: {
                get() {
                    return this.$store.state.customerInformation.addressPart1
                },
                set(value) {
                    this.$store.commit('UPDATE_ADDRESS1', value)
                }
            },
            // addressPart2: {
            //     get() {
            //         return this.$store.state.customerInformation.addressPart2
            //     },
            //     set(value) {
            //         this.$store.commit('UPDATE_ADDRESS2', value)
            //     }
            // },
            postCode: {
                get() {
                    return this.$store.state.customerInformation.postCode
                },
                set(value) {
                    this.$store.commit('UPDATE_POSTCODE', value)
                }
            },
            city: {
                get() {
                    return this.$store.state.customerInformation.city
                },
                set(value) {
                    this.$store.commit('UPDATE_CITY', value)
                }
            },
            country: {
                get() {
                    return this.$store.state.customerInformation.country
                },
                set(value) {
                    this.$store.commit('UPDATE_COUNTRY', value)
                }
            },
            phone: {
                get() {
                    return this.$store.state.customerInformation.phone
                },
                set(value) {
                    this.$store.commit('UPDATE_PHONE', value)
                }
            },
            // region: {
            //     get() {
            //         return this.$store.state.customerInformation.region
            //     },
            //     set(value) {
            //         this.$store.commit('UPDATE_REGION', value)
            //     }
            // },
            notes: {
                get() {
                    return this.$store.state.customerInformation.notes
                },
                set(value) {
                    this.$store.commit('UPDATE_NOTES', value)
                }
            },
            validation() {
                if (
                    this.$store.state.customerInformation.firstName === '' ||
                    this.$store.state.customerInformation.lastName === '' ||
                    this.$store.state.customerInformation.email === '' ||
                    this.$store.state.customerInformation.taxReferenceNumber === '' ||
                    this.$store.state.customerInformation.companyName === '' ||
                    this.$store.state.customerInformation.addressPart1 === '' ||
                    // this.$store.state.customerInformation.addressPart2 === '' ||
                    this.$store.state.customerInformation.postCode === '' ||
                    this.$store.state.customerInformation.city === '' ||
                    this.$store.state.customerInformation.country === '' ||
                    this.$store.state.customerInformation.phone === '' ||
                    // this.$store.state.customerInformation.region === '' ||
                    this.phoneDetails.isValid === false
                ) {
                    return true
                }
            },
            selectCountry() {
                if (this.countryChanged) {
                    if(this.country === '') {
                        this.showCoutryError = true
                        return {
                            'label-error': true
                        }
                    }
                    else this.showCoutryError = false
                } else {
                    this.showCoutryError = false
                    return {
                        'label': this.country === ''
                    }
                }
            },
            checkCountry() {
                return this.countryChanged = true
            }
        },
        methods: {
            submitForm() {
                this.buttonLoading = true;
                let customerData = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email_address: this.email,
                    tax_number: this.taxReferenceNumber,
                    company_name: this.companyName,
                    address: this.addressPart1,
                    city: this.city,
                    // region: this.region,
                    postal_code: this.postCode,
                    country: this.country,
                    phone: this.phoneDetails.formatInternational,
                    notes: this.notes
                }

                this.$axiosPayments.put(`./api/customers/update/${this.customerId}`, customerData
                ).then((response) => {
                    localStorage.setItem('nationalNumber', this.phoneDetails.phoneNumber);
                    localStorage.setItem('countryCode', this.phoneDetails.countryCode);
                    this.$router.push({name: 'payments'})
                    this.buttonLoading = false;
                }).catch(error => {
                    this.$store.dispatch('handleErrorMessage', error.response)
                    this.$store.dispatch('makeSnackbarNotification', {
                        open: true,
                        message: this.$store.state.errorMessage,
                        type: 'error'
                    })
                    this.buttonLoading = false;
                })
            },
            modifyOrder() {
                let path = '/orders/update/' + this.customerId
                this.$router.push(path)
            },

            updatePhone(payload) {
                this.phoneDetails = payload
            }
        },
        mounted() {
            document.getElementById('select-country-id').addEventListener('change',() => {
                this.checkCountry
            });
        }
    }
</script>

<style lang="scss">

    .v-btn {
        margin: 6px 0px;
    }

    .gold-font {
        color: #E6CD97
    }

    .main-heading {
        padding: 0rem 2rem 0.5rem 2rem;
        border-bottom: 1px solid #E6CD97;
        width: fit-content;
        margin: 0 auto;
        font-weight: normal;
    }

    .v-text-field {
        .v-input__control {
            .v-input__slot {
                border: 1px solid #E6CD97 !important;
            }
        }
    }

    #VuePhoneNumberInput_country_selector {
        border: 1px solid #E6CD97;
        border-radius: 4px !important;
    }

    #VuePhoneNumberInput_phone_number {
        border: 1px solid #E6CD97;
        border-radius: 4px !important;
        margin-left: 2% !important;
        width: 98% !important;
    }
    .select-country {
        border: 1px solid #E6CD97;
        border-radius: 4px !important;
        margin-left: 2% !important;
        width: 100% !important;
        font-size: 16px;
        margin-left: 0px !important;
        padding: 15px;
        outline: 0;
    }
    .label {
        color: rgba(0,0,0,0.54);
    }
    .label-error {
        color: #ff5252;
    }
    .label-error option {
        color: black !important;
    }

</style>
