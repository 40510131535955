<template>

    <v-container>
        <v-layout align-center justify-center row fill-height>
            <!--<v-flex md10 offset-md1 lg8 offset-lg2>-->
                <div class="text-xs-center margin">

                    <v-progress-circular
                            :size="100"
                            :width="7"
                            color="#E6CD97"
                            indeterminate
                    ></v-progress-circular>

                    <h1 class="color-gold mt-5">Redirecting to PayPal...</h1>
                </div>

            <!--</v-flex>-->
        </v-layout>
    </v-container>

</template>

<script>
    export default {
        name: "ProceedPaymentsLoader"
    }
</script>

<style scoped>
    .margin {
        margin-top: 30vh
    }

    .color-gold {
        color: #E6CD97
    }
</style>