import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import VueAxios from 'vue-axios'
import {axiosPayments} from './main'

Vue.use(Vuex);
Vue.use(axios, axiosPayments);

export default new Vuex.Store({
    state: {
        snackbar: {
            open: false,
            message: '',
            type: ''
        },
        errorMessage: '',
        gamesOrder: [
            {game_id: 1, player_quantity: 0, instance_quantity: 0},
            {game_id: 2, player_quantity: 0, instance_quantity: 0},
            {game_id: 3, player_quantity: 0, instance_quantity: 0},
            {game_id: 4, player_quantity: 0, instance_quantity: 0},

        ],
        gamesPricesList: [
            {
                perInstanceGoERealLife: 0, perInstanceGoETrivia: 0, perPlayerGoERealLife: 0, perPlayerGoETrivia: 0,
                perInstanceWisdomOfCrowds: 0, perPlayerWisdomOfCrowds: 0, perInstanceSurveys: 0, perPlayerSurveys: 0
            }
        ],
        customerId: localStorage.getItem('customerId') || 0,
        customerInformation: {
            firstName: '',
            lastName: '',
            email: '',
            taxReferenceNumber: '',
            companyName: '',
            addressPart1: '',
            addressPart2: '',
            postCode: '',
            city: '',
            country: '',
            notes: '',
            phone:  ''
            // region: '',
        },
        preloader: true,
        selectionRulesInstances: [
            // v => v <= 500 || 'You cannot choose more than 500 instances at time',
            v => v >= 0 || 'You cannot choose negative number of instances'
        ],
        selectionRulesParticipants: [
            // v => v <= 500 || 'You cannot choose more than 500 participants at time',
            v => v >= 0 || 'You cannot choose negative number of participants'
        ]

    },

    mutations: {

        getErrorMessage(state, error) {
            state.errorMessage = error
        },
        ADD_NOTIFICATION(state, snackbar) {
            state.snackbar = snackbar
        },
        UPDATE_FIRST_NAME(state, value) {
            state.customerInformation.firstName = value
        },
        UPDATE_LAST_NAME(state, value) {
            state.customerInformation.lastName = value
        },
        UPDATE_EMAIL(state, value) {
            state.customerInformation.email = value
        },
        UPDATE_TAXNUMBER(state, value) {
            state.customerInformation.taxReferenceNumber = value
        },
        UPDATE_COMPANY(state, value) {
            state.customerInformation.companyName = value
        },
        UPDATE_ADDRESS1(state, value) {
            state.customerInformation.addressPart1 = value
        },
        UPDATE_ADDRESS2(state, value) {
            state.customerInformation.addressPart2 = value
        },
        UPDATE_POSTCODE(state, value) {
            state.customerInformation.postCode = value
        },
        UPDATE_CITY(state, value) {
            state.customerInformation.city = value
        },
        UPDATE_COUNTRY(state, value) {
            state.customerInformation.country = value
        },
        UPDATE_PHONE(state, value) {
            state.customerInformation.phone = value
        },
        // UPDATE_REGION(state, value) {
        //     state.customerInformation.region = value
        // },
        UPDATE_NOTES(state, value) {
            state.customerInformation.notes = value
        },
        FETCH_INVOICE_DATA(state, value) {
            state.customerInformation.firstName = value.first_name
            state.customerInformation.lastName = value.last_name
            state.customerInformation.email = value.email_address
            state.customerInformation.taxReferenceNumber = value.tax_number
            state.customerInformation.companyName = value.company_name
            state.customerInformation.addressPart1 = value.address
            // state.customerInformation.addressPart2 =
            state.customerInformation.postCode = value.postal_code
            state.customerInformation.city = value.city
            state.customerInformation.country = value.country
            // state.customerInformation.region = value.region
            state.customerInformation.phone = localStorage.getItem('nationalNumber')
            state.customerInformation.notes = value.notes

        },
        // changeNumberOfPlayers(state, dataObj) {
        //     state.gamesOrder = state.gamesOrder.filter((el, index) => {
        //         if ((index + 1) === +dataObj.game_id) {
        //             console.log('value', dataObj.value)
        //             if (dataObj.value > 0) {
        //                 return state.gamesOrder[index].player_quantity = dataObj.value
        //             } else if (dataObj.value === 0) {
        //                 console.log('value2', dataObj.value)
        //                 return state.gamesOrder[index].player_quantity = 0
        //             }
        //         }
        //         // } else {
        //         //     return el
        //         // }
        //     })
        // },
        changeNumberOfPlayers(state, dataObj) {
            if (dataObj.game_id === 1) {
                if (dataObj.value >= 0) {
                    // console.log('value-trivia-play', dataObj.value)
                    return state.gamesOrder[0].player_quantity = dataObj.value
                }
            }
            else if (dataObj.game_id === 2) {
                if (dataObj.value >= 0) {
                    // console.log('value-reallife-play', dataObj.value)
                    return state.gamesOrder[1].player_quantity = dataObj.value
                }
            }
            else if (dataObj.game_id === 3) {
                if (dataObj.value >= 0) {
                    // console.log('value-woc-play', dataObj.value)
                    return state.gamesOrder[2].player_quantity = dataObj.value
                }
            }
            else if (dataObj.game_id === 4) {
                if (dataObj.value >= 0) {
                    // console.log('value-js-play', dataObj.value)
                    return state.gamesOrder[3].player_quantity = dataObj.value
                }
            }
        },

        changeNumberOfInstances(state, dataObj) {
            if (dataObj.game_id === 1) {
                if (dataObj.value >= 0) {
                    // console.log('value-trivia-inst', dataObj.value)
                    return state.gamesOrder[0].instance_quantity = dataObj.value
                }
            }
            else if (dataObj.game_id === 2) {
                if (dataObj.value >= 0) {
                    // console.log('value-reallife-inst', dataObj.value)
                    return state.gamesOrder[1].instance_quantity = dataObj.value
                }
            }
            else if (dataObj.game_id === 3) {
                if (dataObj.value >= 0) {
                    // console.log('value-woc-inst', dataObj.value)
                    return state.gamesOrder[2].instance_quantity = dataObj.value
                }
            }
            else if (dataObj.game_id === 4) {
                if (dataObj.value >= 0) {
                    // console.log('value-js-inst', dataObj.value)
                    return state.gamesOrder[3].instance_quantity = dataObj.value
                }
            }
        },

        loadGamesPricesList(state, gamesPricesList) {
            state.gamesPricesList = gamesPricesList
        },
        setCustomerId(state, customerId) {
            state.customerId = customerId
        },
        setGamesOrder(state, gamesOrder) {
            state.gamesOrder = gamesOrder
        }

    },
    actions: {
        makeSnackbarNotification({commit}, snackbar) {
            commit('ADD_NOTIFICATION', snackbar)
        },
        handleErrorMessage({commit, dispatch}, error) {
            switch (+error.status) {
                case 401:
                    commit('getErrorMessage', error.data.error.message)
                    break;
                case 404:
                    commit('getErrorMessage', 'Page not found. The link is broken.')
                    break;
                case 412:
                    commit('getErrorMessage', error.data.message)
                    break;
                case 500:
                    let errorMessage = 'Server error. Please refresh page and try again.';
                    commit('getErrorMessage', errorMessage)
                    break;
                default:
                    commit('getErrorMessage', error.data.message)
            }
        },
        getGamesPricesList: ({commit, state, dispatch}) => {
            axiosPayments
                .get('./api/payments/price-list')
                .then(response => {
                    return response.data.success.data
                })
                .then(gamePricesList => {
                    commit('loadGamesPricesList', gamePricesList)
                    state.preloader = false
                })
                .catch( error => {
                    dispatch('handleErrorMessage', error.response)
                    dispatch('makeSnackbarNotification', {
                        open: true,
                        message: state.errorMessage,
                        type: 'error'
                    })
                })
        },
        getOrderData: ({commit, dispatch, state}, customerId) => {
            axiosPayments
                .get('/api/orders/details/' + customerId)
                .then(response => {
                    return response.data.success.data
                })
                .then(gamesOrder => {
                    commit('setGamesOrder', gamesOrder)
                    state.preloader = false
                })
                .catch( error => {
                    dispatch('handleErrorMessage', error.response)
                    dispatch('makeSnackbarNotification', {
                        open: true,
                        message: state.errorMessage,
                        type: 'error'
                    })
                })
        },
        getInvoiceData: ({commit, dispatch, state}, customerId) => {
            axiosPayments
                .get('api/customers/details/' + customerId)
                .then(response => {
                    commit('FETCH_INVOICE_DATA', response.data.success.data)
                    state.preloader = false
                })
                .catch( error => {
                    dispatch('handleErrorMessage', error.response)
                    dispatch('makeSnackbarNotification', {
                        open: true,
                        message: state.errorMessage,
                        type: 'error'
                    })
                })
        }
    }
})
