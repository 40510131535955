<template>
    <v-snackbar :color="notification.type" v-model="notification.open" :timeout="0" top>
        <span class="title font-weight-medium">{{notification.message}}</span>
    </v-snackbar>
</template>

<script>
    export default {
        name: "Snackbar",
        computed: {
            notification() {
                return this.$store.state.snackbar
            }
        }
    }
</script>

<style scoped>

</style>