<template>
    <v-layout>
        <v-flex xs12 sm6 offset-sm3>

            <h2 class="ma-2 text-xs-center">Your cart</h2>

            <v-card class="koszyk">
                <v-card-title class="justify-center"><h4 class="text-xs-center">Please confirm your order:</h4></v-card-title>
                <v-card-text>
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs12><h1 class="grey--text">Game of Experts (trivia):</h1></v-flex>
                            <v-flex xs6><div>Number of Instances:</div></v-flex>
                            <v-flex xs4 offset-xs2><div>60</div></v-flex>
                            <v-flex xs6><div>Number of participants:</div></v-flex>
                            <v-flex xs4 offset-xs2><div>60</div></v-flex>
                            <v-flex xs12><h1 class="grey--text">Game of Experts (real-life):</h1></v-flex>
                            <v-flex xs6><div>Number of Instances:</div></v-flex>
                            <v-flex xs4 offset-xs2><div>60</div></v-flex>
                            <v-flex xs6><div>Number of participants:</div></v-flex>
                            <v-flex xs4 offset-xs2><div>60</div></v-flex>
                            <v-flex offset-xs8><div>Total price: $500</div></v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>


            </v-card>

            <v-layout justify-space-between><v-btn>modify order</v-btn><v-btn>proceed to personal data</v-btn></v-layout>


        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: "Cart",
        data() {
            return {
            }
        }
    }
</script>

<style lang="scss">
.koszyk {
    background: blue !important;
}
</style>
