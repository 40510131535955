<template>
    <v-container>

        <v-layout justify-center wrap>
            <v-flex xs12 justify-center align-content-center class="text-xs-center justify-center py-5">
                <h1 class="main-heading">An error has been occurred during transaction</h1>
            </v-flex>

        </v-layout>
        <v-layout align-center justify-center row>
            <v-flex xs12 md10 lg8 xl6>
                <v-card class="px-4 pt-4 pb-3 mb-4">
                    <h2 class="text-xs-center font-weight-regular">Your credit card has not been charged. Please try again.</h2>
                </v-card>
            </v-flex>
        </v-layout>

    </v-container>
</template>

<script>
    export default {
        name: "PaymentError",
        components: {},
        data() {
            return {}
        },
        created() {
            this.$store.state.customerId = localStorage.clear()
        },
        computed: {},
        methods: {}
    }
</script>

<style scoped>
    .gold-font {
        color: #E6CD97
    }

    .main-heading {
        padding: 0rem 2rem 0.5rem 2rem;
        border-bottom: 1px solid #E6CD97;
        width: fit-content;
        margin: 0 auto;
        font-weight: normal;
    }
</style>
